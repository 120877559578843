import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { ReactRenderElement } from './types/types';
import { UserProvider } from './providers/user/user';
import { useAuthProvider } from './providers/auth/auth';
import ErrorPage from './pages/Error';

type Props = {
  children?: any;
};

export type ProviderWrapper = any;

function DataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  /**
   * Define all your providers that needed to be accessed throughout the app.
   * Order matters here, first provider will be at the top of hierarchy...
   */
  const providers: ProviderWrapper[] = [UserProvider];

  return (
    providers.length
      ? providers.reduceRight(
          (children, Provider: ProviderWrapper) => (
            <Provider>{children}</Provider>
          ),
          children,
        )
      : children
  ) as ReactElement;
}

function App({ children }: Props) {
  const { error: authError } = useAuthProvider();

  if (authError) {
    return (
      <ErrorPage
        title='Authentication Error'
        message={
          <>
            Sorry, we're having trouble authenticating your account. Please try
            logging out and logging back in again using the same browser to
            resolve this issue. Thank you for your patience.
          </>
        }
      />
    );
  }

  /**
   * Auth has successfully authenticated the user.
   * Wrapped the Outlet with all the providers defined.
   */
  return (
    <DataProviders>
      <Outlet />
    </DataProviders>
  );
}

export default App;
