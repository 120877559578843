import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import AppNavigationBar from './components/AppNavigationBar';

import { parseURL } from './utils/url';

type Props = {
  children?: any;
};

/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function Home({ children }: Props) {
  const location = useLocation();
  const parsedURL = parseURL(location.search);

  if (parsedURL.report_type_platform === 'desktop') {
    return (
      <>
        <AppNavigationBar />
      </>
    );
  }

  return (
    <>
      <AppNavigationBar />
      <Box display='flex' sx={{ flex: 1 }}>
        <Outlet />
      </Box>
    </>
  );
}

export default Home;
