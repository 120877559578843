import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  FC,
} from 'react';
import clsx from 'clsx';
import { renderToString } from 'react-dom/server';
import { Avatar, Box, Modal, Stack, SxProps, Typography } from '@mui/material';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'quill-mention';
import DOMPurify from 'dompurify';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import SendIcon from '@mui/icons-material/Send';
import SettingsVoiceOutlinedIcon from '@mui/icons-material/SettingsVoiceOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import EmojisButton from '../Emojis/EmojisButton';
import QuillCustomToolbarButton from './QuillCustomToolbarButton';
import { M3Button } from '../M3/M3Button';
import AudioRecorderModalView from './AudioRecorderModalView';
// import ScriptPlaySvgIcon from '../SvgIcons/ScriptPlaySvgIcon';
import PromptPencilSvgIcon from '../SvgIcons/PromptPencilSvgIcon';
import ChatGPTPlayground, {
  ChatGPTPlaygroundProps,
} from '../ChatGPT/ChatGPTPlayground';

import 'react-quill/dist/quill.snow.css';
import './QuillEditor.css';
import { UserSearchItemResponse } from '../../types/profile';
import { useCurrentProfile, useUserSearch } from '../../hooks/profile';
import { getUserInitials, getUserPhotoUrl } from '../../utils/user';
import { useAppProvider } from '../../providers/app/app';
import { useWorkspaceProvider } from '../../providers/workspace/workspace';
import { useUserProvider } from '../../providers/user/user';
import { escapeRegExp } from '../../utils/string';
import { sortBy } from '../../utils/array';
import { useOpenAiPromptConfigurationByNameIdentifier } from '../../hooks/openai';

export type QuillEditorProps = ReactQuillProps & {
  sx?: SxProps;
  withAIAssist?: boolean;
  withAIEditPrompt?: boolean;
  withAudioRecord?: boolean;
  withMentions?: boolean;
  withSubmit?: boolean;
  submitOnEnter?: boolean;
  submitDisabled?: boolean;
  withSaveAndCancel?: boolean;
  chatGPTPlaygroundProps?: ChatGPTPlaygroundProps;
  onSetEditorRef?: (ref: ReactQuill | null) => void;
  onValueChange?: (html: string, sanitized: string) => void;
  onSubmit?: (html: string, sanitized: string) => void;
  onCancel?: () => void;
  onAssistClick?: () => void;
};

type MentionRenderListCallback = (
  matches: MentionUserItem[],
  searchTerm: string,
) => void;

type MentionUserItem = {
  id: string | number;
  first_name: string | null;
  last_name: string | null;
  preferred_name?: string | null;
  photo_url?: string | null;
  value: string;
  mentionId: string | number;
};

const renderMentionItem = function (item: MentionUserItem) {
  return renderToString(
    <Stack className='ql-mention-list-item-stack' data-mention-id={item.id}>
      <Avatar
        src={getUserPhotoUrl(item.photo_url)}
        className='ql-mention-list-item-avatar'
      >
        {getUserInitials(item.preferred_name ?? item.first_name ?? '').initial}
      </Avatar>
      <Typography
        component='div'
        className='ql-mention-list-item-text'
        style={{ fontWeight: 500 }}
      >
        {item.preferred_name ? item.preferred_name : item.first_name}&nbsp;
        {item.preferred_name ? (
          <Typography
            component='span'
            style={{
              fontSize: 13,
              paddingLeft: 8,
              opacity: 0.5,
              fontWeight: 400,
            }}
          >
            {item.first_name} {item.last_name}
          </Typography>
        ) : (
          item.last_name
        )}
      </Typography>
    </Stack>,
  );
};

/**
 * Editor for React-Quill
 * NOTE: A lot of modules in the react-quill doesn't like rerendering, so
 * most of the modules being defined here are being mutated and using a lot of ref
 */
const QuillEditor = ({
  value: initialValue,
  placeholder = 'Write here...',
  withMentions = true,
  onFocus,
  onBlur,
  withSubmit = false,
  withAudioRecord = true,
  withAIAssist = false,
  withAIEditPrompt = false,
  submitOnEnter,
  submitDisabled,
  modules: propsModules,
  formats: propsFormats = [],
  onValueChange,
  onSubmit,
  onSetEditorRef,
  withSaveAndCancel,
  onCancel,
  sx,
  chatGPTPlaygroundProps,
  onAssistClick,
}: QuillEditorProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { squadMembers } = useWorkspaceProvider();
  const { getUser } = useUserProvider();
  const editorRef = useRef<ReactQuill>(null);
  const [value, setValue] = useState<string>(
    (initialValue as unknown as string) ?? '',
  );

  const [isEditPromptOpen, setIsEditPromptOpen] = useState(false);

  // add more flag for ai tools
  const withAITools = withAudioRecord || withAIAssist || withAIEditPrompt;

  /**
   * We whitelist format to allow only certain text to be inserted to the editor
   */
  const formats: ReactQuillProps['formats'] = [
    'bold',
    'italic',
    'strike',
    'underline',
    'list',
    'align',
    ...propsFormats,
  ];

  /** Sanitizing the value when it changes so we have the pure text */
  const sanitizedValue = useMemo(() => {
    // stripping all tags if there's actually a true value
    return DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }).trim();
  }, [value]);

  const valueRef = useRef<{ html: string; sanitized: string }>({
    html: value,
    sanitized: sanitizedValue,
  });
  valueRef.current.html = value;
  valueRef.current.sanitized = sanitizedValue;

  const insertTextToEditor = (text: string, clearText?: boolean) => {
    const editor = editorRef.current?.editor;

    if (!editor) return;

    if (clearText) {
      editor.setText(text);
    } else {
      editor.insertText(editor.getSelection(true)?.index ?? 0, text);
    }
  };

  /**
   * We need to have a reference to the mention callback since it doesn't
   * allow the quill-mentions rerendering
   */
  const searchSuggestionsRef = useRef<{
    searchTerm: string;
    users: UserSearchItemResponse[];
    renderList?: MentionRenderListCallback;
  }>({
    searchTerm: '',
    users: [],
  });

  /**
   * Searches users to be used in mention
   */
  const [searchUsers, setSearchUsers] = useState('');
  const userSearch = useUserSearch(
    { s: searchUsers },
    {
      enabled: !!(searchUsers && withMentions),
    },
  );
  // converts the users response to proper props to mention item
  const getUsersOptions = (
    users: UserSearchItemResponse[],
    searchTerm: string,
  ): MentionUserItem[] => {
    let sqm = squadMembers
      .map(({ id }) => getUser('employee_id', id))
      .filter((u) => !!u);
    let uniqueUserIds = sqm.map((user) => user!.id);
    let userIds = (users ?? []).map((user) => +user.id);
    // get the unique users with the squad be the first to show
    uniqueUserIds = Array.from(new Set([...uniqueUserIds, ...userIds]));

    let localUsers = uniqueUserIds
      .map(
        (id) => getUser('user_id', id) ?? users.find((user) => +user.id === id),
      )
      .filter((u) => !!u)
      .filter((u) => +u!.id !== currentProfile!.id);

    // Filter the list for user using their preferred name or full name
    const sRgx = new RegExp(escapeRegExp(searchTerm), 'i');
    localUsers = searchTerm
      ? localUsers.filter((u) => {
          return u!.preferred_name
            ? sRgx.test(u!.preferred_name)
            : sRgx.test(`${u!.first_name} ${u!.last_name}`);
        })
      : localUsers;

    const mentionUsers = localUsers.map((user) => ({
      id: user!.id,
      first_name: user!.first_name,
      last_name: user!.last_name,
      preferred_name: user!.preferred_name,
      photo_url: user!.photo_url,
      // value is used to display in the editor
      value: user!.preferred_name ?? `${user!.first_name}`,
      // mentionId is used to attach the data-mention-id in the html
      mentionId: user!.id,
    }));

    return sortBy(mentionUsers, 'first_name', true, 'asc');
  };

  /**
   * Keyboard ref/bindings, to always update to always have the correct handler
   */
  const keyboardModuleRef = useRef<{ onSubmit: QuillEditorProps['onSubmit'] }>({
    onSubmit,
  });
  keyboardModuleRef.current.onSubmit = onSubmit;

  /**
   * Defining keyboard module bindings only once
   */
  const keyboardModule = useMemo(() => {
    return {
      bindings: {
        shift_enter: {
          key: 13,
          shiftKey: true,
          handler: function () {
            insertTextToEditor('\n');
          },
        },
        enter: {
          key: 13,
          handler: function () {
            if (submitOnEnter) {
              keyboardModuleRef.current.onSubmit?.(
                valueRef.current.html,
                valueRef.current.sanitized,
              );
            } else {
              insertTextToEditor('\n');
            }
          },
        },
      },
    };
  }, [keyboardModuleRef, valueRef, submitOnEnter]);

  /**
   * Modules for quill-editor
   */
  const modules: ReactQuillProps['modules'] = {
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        'strike',
        { list: 'bullet' },
        { list: 'ordered' },
      ],
    ],
    keyboard: keyboardModule,
    ...propsModules,
  };

  /**
   * Use useCallback to get the source, quill-mention throws an error
   * if the callback has changed
   */
  const mentionSourceCallback = useCallback(
    (
      searchTerm: string,
      renderList: MentionRenderListCallback,
      mentionChar: '@' | '#' = '@',
    ) => {
      /**
       * Mutate the callback and the search term to be used to render later
       */
      searchSuggestionsRef.current.renderList = renderList;
      searchSuggestionsRef.current.searchTerm = searchTerm;
      // check for the @ symbol to search for users we want to mention
      if (mentionChar === '@') {
        setSearchUsers(searchTerm);
      }
      renderList(
        getUsersOptions(searchSuggestionsRef.current.users, searchTerm),
        searchTerm,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Add only the mention module when it's required */
  if (withMentions) {
    formats.push('mention');
    modules.mention = {
      mentionDenotationChars: ['@'],
      source: mentionSourceCallback,
      renderItem: renderMentionItem,
      dataAttributes: ['mentionId'],
    };
  }

  /**
   * Sets the editor when to ref to outside component
   */
  useEffect(() => {
    onSetEditorRef?.(editorRef.current);
  }, [editorRef, onSetEditorRef]);

  /**
   * When new search suggestions comes, parsed the data that will be used
   * to render the list
   */
  useLayoutEffect(() => {
    const users = userSearch.data ?? [];
    const renderList = searchSuggestionsRef.current.renderList;
    const searchTerm = searchSuggestionsRef.current.searchTerm;
    searchSuggestionsRef.current.users = users;
    renderList?.(getUsersOptions(users, searchTerm), searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearch.data, searchSuggestionsRef]);

  /**
   * Check value updated so we can notify listeners
   */
  useEffect(() => {
    let currentValue = !sanitizedValue ? '' : value;
    onValueChange?.(currentValue, sanitizedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanitizedValue, value]);

  useEffect(() => {
    const editor = editorRef.current?.getEditor();
    if (editor) {
      editor.root.dataset.placeholder = placeholder;
    }
  }, [editorRef, placeholder]);

  return (
    <Box
      className={clsx('react-quill-editor-container', {
        'chatgpt-playground-open': isEditPromptOpen,
      })}
      position='relative'
      sx={sx}
    >
      {withAITools && (
        <QuillAIToolbars
          insertTextToEditor={insertTextToEditor}
          withAIAssist={withAIAssist}
          withAIEditPrompt={withAIEditPrompt}
          withAIEditPromptActive={isEditPromptOpen}
          chatGPTPlaygroundProps={chatGPTPlaygroundProps}
          onAssistClick={onAssistClick}
          onEditPromptClick={() => setIsEditPromptOpen(!isEditPromptOpen)}
        />
      )}
      <Box position='relative'>
        <ReactQuill
          ref={editorRef}
          value={value}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          onChange={setValue}
          onFocus={onFocus}
          onBlur={onBlur}
          className='react-quill-editor-wrapper'
        />
        <QuillCustomBottomToolbars
          editorRef={editorRef}
          editorValue={value}
          sanitizedValue={sanitizedValue}
          withMentions={withMentions}
          withSubmit={withSubmit}
          withSaveAndCancel={withSaveAndCancel}
          submitDisabled={submitDisabled}
          insertTextToEditor={insertTextToEditor}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Box>
      {withAIEditPrompt && isEditPromptOpen && (
        <ChatGPTPlayground {...chatGPTPlaygroundProps} />
      )}
    </Box>
  );
};

export default QuillEditor;

type QuillCustomBottomToolbarsProps = {
  editorRef: RefObject<ReactQuill>;
  editorValue: string;
  sanitizedValue: string;
  withMentions: QuillEditorProps['withMentions'];
  withSubmit: QuillEditorProps['withSubmit'];
  submitDisabled: QuillEditorProps['submitDisabled'];
  withSaveAndCancel: QuillEditorProps['withSaveAndCancel'];
  onSubmit: QuillEditorProps['onSubmit'];
  onCancel: QuillEditorProps['onCancel'];
  insertTextToEditor: (text: string) => void;
};
function QuillCustomBottomToolbars({
  editorValue,
  sanitizedValue,
  withMentions,
  withSubmit,
  submitDisabled,
  withSaveAndCancel,
  onSubmit,
  onCancel,
  insertTextToEditor,
}: QuillCustomBottomToolbarsProps) {
  const { isDarkMode } = useAppProvider();
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [isSendActive, setIsSendActive] = useState(false);

  const isSubmitDisabled = submitDisabled ? submitDisabled : !isSendActive;

  const onSubmitClick = () => {
    if (!isSubmitDisabled) {
      onSubmit?.(editorValue, sanitizedValue);
    }
  };

  const actionButtonStyle = {
    height: 25,
    lineHeight: 1,
    fontSize: 13,
    width: 'initial',
    minWidth: 'initial',
    padding: '0 14px',
  };

  /**
   * Allow only to send
   */
  useEffect(() => {
    setIsSendActive(!!sanitizedValue);
  }, [sanitizedValue, setIsSendActive]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 40,
        zIndex: 1,
        position: 'absolute',
      }}
      style={{
        paddingLeft: 5,
        paddingRight: 10,
      }}
      className='react-quill-bottom-toolbar'
    >
      <Stack flex={1} direction='row'>
        <QuillCustomToolbarButton
          content='😀'
          active={emojiOpen}
          onClick={() => {
            setEmojiOpen(true);
          }}
          style={{ paddingTop: '2px' }}
        >
          <EmojisButton
            disableRipple
            open={emojiOpen}
            setOpen={setEmojiOpen}
            onSelect={(text: string) => {
              insertTextToEditor(text);
            }}
          />
        </QuillCustomToolbarButton>
        {withMentions && (
          <QuillCustomToolbarButton
            content={<AlternateEmailOutlinedIcon />}
            onClick={() => {
              insertTextToEditor('@');
            }}
          />
        )}
      </Stack>
      {withSaveAndCancel ? (
        <Stack direction='row' flexWrap='nowrap'>
          <M3Button style={actionButtonStyle} onClick={onCancel}>
            Cancel
          </M3Button>
          <M3Button
            color='primary'
            variant='contained'
            style={{
              ...actionButtonStyle,
              marginLeft: 8,
            }}
            disabled={isSubmitDisabled}
            onClick={onSubmitClick}
          >
            Save
          </M3Button>
        </Stack>
      ) : (
        withSubmit && (
          <QuillCustomToolbarButton
            content={
              <SendIcon
                style={{
                  color: isDarkMode
                    ? 'var(--md-sys-color-on-background-light)'
                    : 'var(--md-sys-color-on-primary-light)',
                  fontSize: 16,
                }}
              />
            }
            disabled={isSubmitDisabled}
            style={{
              background: !isSubmitDisabled
                ? isDarkMode
                  ? 'var(--md-ref-palette-primary80)'
                  : 'var(--md-ref-palette-primary40)'
                : isDarkMode
                ? 'var(--md-ref-palette-neutral20)'
                : 'var(--md-ref-palette-neutral90)',
            }}
            onClick={onSubmitClick}
          />
        )
      )}
    </Stack>
  );
}

type DummyComponentProps = {
  close?: () => void;
};
const DummyComponent = forwardRef((props: DummyComponentProps, ref) => null);

type QuillAIToolbarsProps = {
  withAIAssist?: boolean;
  withAIEditPrompt?: boolean;
  withAIEditPromptActive?: boolean;
  chatGPTPlaygroundProps?: ChatGPTPlaygroundProps;
  insertTextToEditor: (text: string, clearText?: boolean) => void;
  onEditPromptClick?: () => void;
  onAssistClick?: () => void;
};
type QuillAIToolbarsState = {
  open: boolean;
  type: 'record' | null;
  Component: FC<any>;
};
function QuillAIToolbars({
  insertTextToEditor,
  withAIAssist,
  withAIEditPrompt,
  withAIEditPromptActive,
  onEditPromptClick,
  onAssistClick,
  chatGPTPlaygroundProps,
}: QuillAIToolbarsProps) {
  const { isDarkMode } = useAppProvider();
  const [speechToTextId, setSpeechToTextId] = useState<number>(0);
  const [modalState, setModalState] = useState<QuillAIToolbarsState>({
    open: false,
    type: null,
    Component: DummyComponent,
  });
  const aiButtonSx: SxProps = {
    pl: 1.1,
    pr: 1.2,
    height: 25,
    fontSize: 12,
  };
  const promptConfig = useOpenAiPromptConfigurationByNameIdentifier(
    {
      prompt_identifier: chatGPTPlaygroundProps?.prompt_identifier!,
    },
    {
      enabled:
        !!withAIEditPrompt && !!chatGPTPlaygroundProps?.prompt_identifier,
    },
  );
  const isConfigNotSet =
    !(promptConfig.isSuccess && promptConfig.data) || !!promptConfig.error;

  return (
    <>
      <Stack
        gap={1}
        height={45}
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        sx={{
          pr: 1,
          pl: 1,
          top: 0,
          right: 0,
          zIndex: 1,
          position: 'absolute',
        }}
        className='react-quill-bottom-toolbar react-quill-ai-toolbar'
      >
        {withAIEditPrompt && (
          <M3Button
            sx={aiButtonSx}
            disableRipple
            active={withAIEditPromptActive}
            onClick={onEditPromptClick}
            style={{
              color: isConfigNotSet
                ? isDarkMode
                  ? 'var(--md-ref-palette-error80)'
                  : 'var(--md-ref-palette-error40)'
                : undefined,
            }}
          >
            <PromptPencilSvgIcon
              width={22}
              height={22}
              fillColor={
                isConfigNotSet
                  ? isDarkMode
                    ? 'var(--md-ref-palette-error80)'
                    : 'var(--md-ref-palette-error40)'
                  : isDarkMode
                  ? 'var(--md-sys-color-on-surface-dark)'
                  : 'var(--md-sys-color-on-surface-light)'
              }
            />
            &nbsp;&nbsp;Edit Prompt
          </M3Button>
        )}
        {withAIAssist && (
          <M3Button sx={aiButtonSx} onClick={onAssistClick}>
            <EditOutlinedIcon style={{ fontSize: 16 }} />
            Assist
          </M3Button>
        )}
        <QuillCustomToolbarButton
          active={modalState.open && modalState.type === 'record'}
          content={<SettingsVoiceOutlinedIcon style={{ fontSize: 16 }} />}
          onClick={() => {
            setModalState({
              open: true,
              type: 'record',
              Component: AudioRecorderModalView,
            });
          }}
        />
      </Stack>
      <Modal open={modalState.open}>
        <modalState.Component
          close={() => {
            setModalState((state) => ({
              ...state,
              open: false,
            }));
          }}
          onReset={() => setSpeechToTextId(speechToTextId + 1)}
          onSubmit={(data: any) => {
            if (modalState.type === 'record') {
              insertTextToEditor(data.text);
            }
          }}
          key={speechToTextId}
        />
      </Modal>
    </>
  );
}
