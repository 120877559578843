import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { WorkspaceItem } from '.';
import ErrorPage from '../../pages/Error';
import AppLoader from '../../components/Loaders/AppLoader';

import { useCurrentProfile } from '../../hooks/profile';
import { IterableObject, ReactRenderElement } from '../../types/types';
import { standaloneRoutes } from '../app';
import { DirectReportPeer } from '../../types/profile';
import { sortBy } from '../../utils/array';
import { parseURL } from '../../utils/url';

type IdNameItemObject = {
  id: string | null;
  name: string | null;
};

type WorkspaceProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */
type WorkspaceInitialState = {
  current?: WorkspaceItem | null;
  items: WorkspaceItem[];
  squadMembers: IdNameItemObject[];
};
/**
 * Reducers that mutate the state
 */
type WorkspaceReducers = {
  setCurrent: (item: WorkspaceItem, replaceItems?: boolean) => void;
  addWorkspaceListItem: (
    item: WorkspaceItem | WorkspaceItem[],
    squadMembers: IdNameItemObject[],
    replaceItems?: boolean,
  ) => void;
};
/**
 * Single store
 */
type WorkspaceStore = WorkspaceInitialState & WorkspaceReducers;
/**
 * Initial state / store
 */
const initialStore: WorkspaceStore = {
  current: null,
  items: [],
  squadMembers: [],
  setCurrent: (item: WorkspaceItem, replaceItems?: boolean) => {
    throw new Error('Implementation required');
  },
  addWorkspaceListItem: (
    item: WorkspaceItem | WorkspaceItem[],
    squadMembers: IdNameItemObject[],
    replaceItems?: boolean,
  ) => {
    throw new Error('Implementation required');
  },
};
/**
 * Context Instance
 */
const WorkspaceContext = createContext<WorkspaceStore>(initialStore);

export function useWorkspaceProvider(): WorkspaceStore {
  return useContext(WorkspaceContext);
}

export function WorkspaceProvider({ children }: WorkspaceProviderProps) {
  const [state, setState] = useState<WorkspaceStore>(initialStore);
  const navigate = useNavigate();
  const location = useLocation();
  const routeParams = useParams();
  const { data: userProfile } = useCurrentProfile();
  const parsedURL = parseURL(location.search);

  /**
   * Define all the handlers here how you want to mutate the state
   */
  function setCurrentWorkspace(item: WorkspaceItem, replaceItems?: boolean) {
    setState((state) => {
      state = { ...state, current: item };
      if (replaceItems) {
        state.items = [item];
      } else {
        state.items = state.items.map((itm) => {
          if (itm.id === item.id) return { ...itm, ...item };
          return itm;
        });
      }

      return state;
    });
  }

  function addWorkspaceListItem(
    item: WorkspaceItem | WorkspaceItem[],
    squadMembers: IdNameItemObject[],
    replaceItems?: boolean,
  ) {
    setState((state) => {
      const items: WorkspaceItem[] = replaceItems ? [] : [...state.items];
      const checkWorkspace = (itm: WorkspaceItem) => {
        const indexType = items.findIndex(
          (ws) => ws.type === 'jumper_division_partner',
        );
        /**
         * Check for workspace type.
         */
        if (indexType > -1) {
          items[indexType] = { ...itm };
        } else {
          items.push(itm);
        }
      };

      if (Array.isArray(item)) {
        item.map(checkWorkspace);
      } else {
        checkWorkspace(item);
      }

      return { ...state, items, squadMembers };
    });
  }

  /**
   * Define all side effects here...
   */
  useEffect(() => {
    /**
     * Check if there's no current set yet, so we set the first item we can find
     */
    if (!state.current && state.items.length) {
      const item =
        state.items.find((itm) => `${itm.id}` === routeParams.workspaceId) ??
        state.items[0];
      setCurrentWorkspace(item);

      /**
       * Navigate only the first view of workspace if it's not a standalone route
       */
      if (
        !standaloneRoutes.some((s) => location.pathname.includes(s)) &&
        location.pathname === '/'
      ) {
        navigate(`/manifest/${item.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.workspaceId, state.current, state.items.length, location]);

  /**
   * When user's have have division associated to the account
   * add it as a workspace list item
   */
  useEffect(() => {
    const items: WorkspaceItem[] = [];
    let mySquadDivisions: IterableObject<IdNameItemObject> = {};
    let mySquadMembers: IterableObject<IdNameItemObject> = {};
    const mapDirectReportPeer = (dr: DirectReportPeer) => {
      if (dr.division_id) {
        mySquadDivisions[dr.division_id] = {
          id: dr.division_id,
          name: dr.division_name,
        };
      }

      if (dr.id) {
        mySquadMembers[dr.id] = {
          id: dr.id,
          name: dr.name,
        };
      }
    };
    const getDirectReportPeerDivisionMembers = () => {
      const divisions = Object.keys(mySquadDivisions).map(
        (key) => mySquadDivisions[key],
      );
      const members = sortBy(
        Object.keys(mySquadMembers).map((key) => mySquadMembers[key]),
        'name',
      );
      return {
        divisions,
        members,
      };
    };

    if (userProfile && userProfile.division_id) {
      items.push({
        id: userProfile.division_id,
        name: userProfile.division_name,
        type: 'division_partner',
      } as WorkspaceItem);
    }

    if (userProfile && userProfile.direct_reports?.length) {
      userProfile.direct_reports.forEach(mapDirectReportPeer);
    }

    if (userProfile && userProfile.peers?.length) {
      userProfile.peers.forEach(mapDirectReportPeer);
    }

    if (userProfile && userProfile.reports_to) {
      mapDirectReportPeer(userProfile.reports_to);
    }

    if (
      userProfile &&
      (userProfile.direct_reports?.length ||
        userProfile.peers?.length ||
        userProfile.reports_to)
    ) {
      mapDirectReportPeer({
        id: userProfile.employee_id as string,
        name: `${userProfile.first_name || ''} ${
          userProfile.last_name || ''
        }`.trim(),
        division_id: userProfile.division_id as unknown as string,
        division_name: userProfile.division_name,
      });
    }
    /**
     * Check if there's any division or members then surely we have a squad
     */
    const { divisions, members } = getDirectReportPeerDivisionMembers();
    if (divisions.length || members.length) {
      items.unshift({
        id: 'my-squad',
        name: 'My Squad',
        type: 'my_squad',
        queryId: divisions.map((dr) => dr.id).join(','),
        queryName: divisions.map((dr) => dr.name).join(','),
        memberId: members.map((dr) => dr.id).join(','),
        memberName: members.map((dr) => dr.name).join(','),
        members,
      } as WorkspaceItem);
    }

    if (userProfile && userProfile.divisions_from_assignments?.length) {
      const divAssignments = sortBy(
        userProfile.divisions_from_assignments,
        'name',
        true,
        'ASC',
      );
      divAssignments
        .filter((div) => +div.id !== userProfile.division_id)
        .forEach((div) => {
          items.push({
            id: div.id,
            name: div.name,
            type: 'division_partner',
          });
        });
    }

    if (items.length) {
      addWorkspaceListItem(items, members, true);
    }
  }, [userProfile]);

  /**
   * Check if user don't have any division before proceeding to the app.
   * Prevent user from accessing the app if there's no division
   */
  if (
    userProfile &&
    !userProfile.division_id &&
    !userProfile.direct_reports.length &&
    !userProfile.peers.length &&
    !userProfile.reports_to &&
    !userProfile.divisions_from_assignments.length
  ) {
    return (
      <ErrorPage
        title='Division Not Found'
        message={
          <>
            The app requires to have at least one division. <br />
            Please contact the admin if this issue exist.
          </>
        }
      />
    );
  }

  /**
   * Do not proceed if there's no workspace selected, we might have
   * to show some errors here if there's no workspace available
   */
  if (!state.current) {
    if (parsedURL.report_type_platform === 'desktop') return null;

    return <AppLoader />;
  }

  return (
    <WorkspaceContext.Provider
      value={{
        ...state,
        setCurrent: setCurrentWorkspace,
        addWorkspaceListItem,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}
