import React from 'react';
import { Global, css } from '@emotion/react';

import { useAppProvider, workspaceWidth } from '../../providers/app/app';

type Props = {};

/**
 * This palette was generated with m3 theme builder:
 * https://m3.material.io/theme-builder#/custom
 */
const M3ThemePalette = (props: Props) => {
  const {
    paletteSource,
    drawerWidth,
    toolbarHeight,
    subToolbarHeight,
    isDarkMode,
  } = useAppProvider();

  return (
    <Global
      key={paletteSource}
      styles={css`
        ${paletteSource}

        body, html {
          background: ${isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-on-primary-light)'} !important;
          font-family: 'Roboto Flex', 'Lato', 'Helvetica Neue', sans-serif;
        }

        :root {
          --app-drawer-width: ${drawerWidth}px;
          --app-drawer-width-no-workspace: ${drawerWidth - workspaceWidth}px;
          --app-toolbar-height: ${toolbarHeight}px;
          --app-subtoolbar-height: ${subToolbarHeight}px;
        }
      `}
    />
  );
};

export default M3ThemePalette;
