import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { getRandomBotUrl } from '../../services/go2-bots';

import { DailyQuoteResponse } from '../../types/quote';
import { parseURL } from '../../utils/url';
import { ReportType } from '../../types/report';
import M3SurfaceContainer from '../M3/M3SurfaceContainer';
import { useFetchTasks } from '../../hooks/edit-time';
import { M3OptionItem } from '../Popover/BasicPopoverWithSearch';
import { ITaskList } from '../EditTime/constants/edit-time-details-form.interface';
import { useCallback, useMemo, useRef, useState } from 'react';
import { IterableObject } from '../../types/types';
import { useCurrentProfile } from '../../hooks/profile';
import { M3MenuItem } from '../M3/M3MenuItem';
import PerfectScrollbar from '../PerfectScrollbar';
import { M3Button } from '../M3/M3Button';
import { useWindowDimension } from '../../hooks/utils/window';
import { useAppProvider } from '../../providers/app/app';

type QuoteViewProps = {
  quoteToday?: DailyQuoteResponse;
  fullView?: boolean;
  isSod?: boolean;
  reportType: ReportType;
  onSelectFocus?: (item: M3TaskRecordOption) => void;
};

const fallbackDailyQuote: DailyQuoteResponse = {
  id: 0,
  image: getRandomBotUrl(1),
  created: moment.utc().format(),
  modified: moment.utc().format(),
  quote:
    'Attracting and building a great global support team is a whole art in itself, one we are perfecting in order to help move your business forward.',
  color: '#eae98f',
  position: 'left',
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
};

const sideSheetWidth = 284;

const QuoteView = ({
  quoteToday,
  fullView,
  onSelectFocus,
  reportType,
}: QuoteViewProps) => {
  quoteToday = quoteToday || fallbackDailyQuote;
  const position = (quoteToday.position || 'left').toLowerCase() as
    | 'left'
    | 'right';

  const { report_type_platform } = parseURL(window.location.search);
  const showFocuses =
    report_type_platform === 'desktop' && reportType === 'SOD';

  return (
    <Box
      sx={{
        p: 5,
        ml: -4,
        mr: -4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        ...(fullView
          ? {
              height: `calc(100vh - 64px - 37px)`,
            }
          : {}),
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          background: quoteToday.color,
        }}
      />
      <Box
        sx={{
          pt: 5,
          pl: 5,
          pr: 5,
          pb: '240px',
          minHeight: 400,
          position: 'relative',
          ...(showFocuses
            ? {
                gap: 4,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }
            : null),
        }}
      >
        <Typography
          component='h4'
          variant='h4'
          fontWeight={300}
          textAlign='center'
          style={{
            color: '#000',
            paddingRight: showFocuses ? sideSheetWidth : 0,
          }}
        >
          {quoteToday.quote}
        </Typography>
      </Box>
      <Box
        component='img'
        sx={{
          // bottom: -35,
          // maxHeight: 300,
          bottom: -60,
          maxHeight: 350,
          position: 'absolute',
          ...{
            [position]:
              position === 'right' && showFocuses ? sideSheetWidth : 0,
          },
        }}
        src={quoteToday.image}
      />
      {!showFocuses && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            bottom: 20,
            ...{ [position === 'left' ? 'right' : 'left']: 20 },
          }}
        />
      )}
      {showFocuses && <FocusSelection onSelectFocus={onSelectFocus} />}
    </Box>
  );
};

export default QuoteView;

type M3TaskRecordOption = M3OptionItem<ITaskList & { isFolder?: boolean }>;
type FocusSelectionProps = {
  onSelectFocus?: QuoteViewProps['onSelectFocus'];
};
function FocusSelection({ onSelectFocus }: FocusSelectionProps) {
  const { toolbarHeight } = useAppProvider();
  const { data: currentProfile } = useCurrentProfile();

  const [selected, setSelected] = useState<M3TaskRecordOption | null>(null);

  const sheetRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { data } = useFetchTasks({
    limit: 200,
    is_active: true,
    assigned_to: currentProfile!.id.toString(),
  });
  const taskList = data?.results;

  const taskListData: M3TaskRecordOption[] = useMemo(() => {
    const items = (taskList ?? []).filter(
      (item) => item.name !== 'Manifest' && item.name !== 'Break',
    );

    const taskItems = Object.values(
      items.reduce(
        (accu, curr) => {
          const { jobs } = curr;

          // get the user id details to get the job_description_id
          const { job_description_id } = currentProfile!;

          // get first the specific job id from user and check the jobs array
          const userJob = jobs.find(
            (job) => job.id === job_description_id?.toString(),
          );
          // if userJob has value, get the id, otherwise, get the first job id from jobs.
          let jobId = userJob?.id ?? jobs[0]?.id;
          let jobName = userJob?.name ?? jobs[0]?.name;
          let folderId: string;
          let folderName: string;

          if (jobId) {
            folderId = `job-${jobId}`;
            folderName = jobName;
          } else {
            folderId = 'uncategorized';
            folderName = 'Uncategorized';
          }

          const folder = accu[folderId] || {};
          folder.id = folderId;
          folder.name = folderName;
          folder.tasks = [...(folder.tasks || []), curr];
          accu[folderId] = folder;

          return accu;
        },
        {} as IterableObject<{
          id: number | string;
          name: string;
          tasks: ITaskList[];
        }>,
      ),
    );

    let options: M3TaskRecordOption[] = [];

    taskItems.forEach((folder) => {
      const { id: folderId, name: folderName } = folder;

      options.push({
        id: folderId,
        label: folderName,
        props: {
          isFolder: true,
        },
      } as M3TaskRecordOption);

      folder.tasks.forEach((task) => {
        const { id: taskId, name: taskLabel } = task;

        options.push({
          id: taskId,
          label: taskLabel,
          props: task,
        });
      });
    });

    return options;
  }, [taskList]);

  const handleOnSelect = useCallback(() => {
    onSelectFocus?.(selected!);
    // eslint-disable-next-line
  }, [selected]);

  useWindowDimension();

  return (
    <Box
      ref={wrapperRef}
      sx={{
        top: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'stretch',
      }}
    >
      <M3SurfaceContainer borderRadius={0} elevation={1}>
        {/* <Typography
          component='h4'
          fontWeight={300}
          textAlign='center'
          style={{
            fontSize: 14,
            padding: '2px 0',
            background: isDarkMode
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(0, 0, 0, 0.05)',
          }}
        >
          Select your Focus
        </Typography> */}
        <Box
          ref={sheetRef}
          sx={{
            overflow: 'hidden',
            width: sideSheetWidth,
            height: `calc(100vh - ${toolbarHeight * 2 - 4}px)`,
          }}
        >
          <PerfectScrollbar
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              width: sideSheetWidth,
              height: sheetRef.current?.clientHeight
                ? sheetRef.current?.clientHeight
                : undefined,
            }}
            options={{
              suppressScrollX: true,
            }}
          >
            {taskListData.map((item) => {
              const active = item.id === selected?.id;

              if (item.props?.isFolder) {
                return (
                  <Stack
                    key={item.id}
                    gap={1}
                    flex={1}
                    direction='row'
                    alignItems='center'
                    justifyContent='flex-end'
                    style={{
                      opacity: 0.29,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Divider />
                    </div>
                    <Typography
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {item.label}
                    </Typography>
                    <div style={{ width: 20, minWidth: 20 }}>
                      <Divider />
                    </div>
                  </Stack>
                );
              }

              return (
                <M3MenuItem
                  active={active}
                  key={item.id}
                  onClick={() => setSelected(item)}
                  sx={{
                    py: 1,
                    height: 'initial',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item.label}
                </M3MenuItem>
              );
            })}
          </PerfectScrollbar>
        </Box>
        <Divider />
        <Box display='flex' alignItems='center' justifyContent='center'>
          <M3Button
            color='primary'
            variant='contained'
            sx={{
              my: 1,
              minWidth: 100,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            disabled={!selected}
            onClick={handleOnSelect}
          >
            {selected ? 'Select' : <>&nbsp;&nbsp;Select a Focus&nbsp;&nbsp;</>}
          </M3Button>
        </Box>
      </M3SurfaceContainer>
    </Box>
  );
}
