/** dependencies */
import { ReactElement, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'moment-timezone';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

/** components */
import AppLoader from './components/Loaders/AppLoader';
import HtmlBodyListener from './components/Util/HtmlBodyListener';
import M3ThemePalette from './components/M3/M3ThemePalette';

/** hooks */
import { useCurrentProfile } from './hooks/profile';

/** pages */
import Login from './pages/Login';

/** providers */
import { AppProvider } from './providers/app/app';
import { AuthProvider, useAuthProvider } from './providers/auth/auth';

/** services */

/** types */
import { ReactRenderElement } from './types/types';

/** utils */
import { setToLocalStorage } from './utils/localstorage';
import { parseURL } from './utils/url';

/** app */
import App, { ProviderWrapper } from './App';
import Home from './Home';

/** styles */
import './styles';
import theme from './assets/theme';
import * as pusher from './services/pusher';

const queryClient = new QueryClient();

const RenderRoutes = () => {
  const { isAuthenticated, isDoneAuthenticating, getTokenSilently } =
    useAuthProvider();
  const { isLoading, data: userProfile } = useCurrentProfile(
    {},
    { enabled: isAuthenticated },
  );
  let content: ReactRenderElement = null;
  let loader: ReactRenderElement = null;
  const parsedURL = parseURL(window.location.search);

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    loader = <AppLoader />;
  }

  if (!isDoneAuthenticating || (!userProfile && isLoading)) {
    content = null;
  } else {
    content = renderMainRoutes();
  }

  useEffect(() => {
    if (userProfile) {
      /**
       * Save the type(role) of user who last logged in the storage
       */
      setToLocalStorage(
        'last_user_login_role',
        userProfile.roles.join(','),
        true,
      );

      /**
       * Initialize pusher on init, passing token silently
       */
      pusher
        .init({
          getTokenSilently,
        })
        .signin();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, getTokenSilently]);

  return (
    <>
      <M3ThemePalette />
      <HtmlBodyListener />
      {parsedURL.report_type_platform !== 'desktop' && loader}
      {isDoneAuthenticating && (!isLoading || !!userProfile) && (
        <Routes>{content}</Routes>
      )}
    </>
  );
};

const renderUnauthorizedRoutes = () => {
  return (
    <>
      <Route path='/login' element={<Login />} />
    </>
  );
};

/**
 * List of routes that need authentication, should passed children
 * that requires authentication
 */
const renderAuthorizedRoutes = (children: ReactRenderElement) => {
  return (
    <>
      <Route path='/' element={<App />}>
        {children}
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </>
  );
};

const renderMainRoutes = () => {
  return (
    <>
      {renderUnauthorizedRoutes()}
      {renderAuthorizedRoutes(
        <Route path='' element={<Home />}>
          <Route path='/manifest/:workspaceId' element={null} />
        </Route>,
      )}
    </>
  );
};

/**
 * List of providers that doesn't need authorized user
 */
function RootDataProviders({
  children,
}: {
  children: ReactRenderElement;
}): ReactElement {
  const providers: ProviderWrapper[] = [AppProvider];

  return providers.reduceRight(
    (children, Provider: ProviderWrapper) => <Provider>{children}</Provider>,
    children,
  ) as ReactElement;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <RootDataProviders>
                    <RenderRoutes />
                  </RootDataProviders>
                </SnackbarProvider>
              </LocalizationProvider>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </>,
);
