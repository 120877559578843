function getBooleanValue(value?: string): boolean {
  try {
    return JSON.parse(value!) as boolean;
  } catch (e) {
    return false;
  }
}

export type FeatureConfigKeys = 'team_schedule_enabled' | 'sidecar_enabled';

type FeatureConfig = {
  [K in FeatureConfigKeys]: boolean;
};

const feature: FeatureConfig = {
  team_schedule_enabled: getBooleanValue(
    process.env.REACT_APP_TEAM_SCHEDULE_ENABLED,
  ),
  sidecar_enabled: getBooleanValue(process.env.REACT_APP_SIDECAR_ENABLED),
};

/**
 * Define the
 */
export default feature;
