import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import SODForm, { SODFormModalViewProps } from './SodEodForm/SODForm';
import EODForm from './SodEodForm/EODForm';
import SodEodSelectionView from './SodEodForm/SodEodSelectionView';
import SodEodErrorView from './SodEodForm/SodEodErrorView';

import { useUserShiftReportingStatus } from '../hooks/profile';
import { ReportType } from '../types/report';
import { parseURL, toURL } from '../utils/url';

type Props = {
  children?: any;
};

type SodEodState = {
  open: boolean;
  type?: ReportType | 'SOD_EOD';
  Component: FC<SODFormModalViewProps | any>;
};

const DummyComponent = forwardRef((props, ref) => null);

function AppNavigationBar({ children }: Props) {
  const navigate = useNavigate();
  const { pathname, search: locationSearch } = useLocation();

  const [sodEodState, setSodEodState] = useState<SodEodState>({
    open: false,
    Component: DummyComponent,
  });
  const parsedURL = parseURL(locationSearch);

  const shiftReportingStatus = useUserShiftReportingStatus(
    { type: 'SOD_EOD' },
    {
      cacheTime: 0,
      refetchInterval: 60 * 1000,
      refetchOnWindowFocus: true,
    },
  );
  const shiftReportingStatusData = shiftReportingStatus.data;

  const onStartEndClick = (type: SodEodState['type']) => {
    const parsed = parseURL(locationSearch);
    navigate(
      toURL(pathname, {
        ...parsed,
        report_type_open: type,
      }),
      { replace: true },
    );
  };

  const renderPlanRealityModal = () => {
    return (
      <Modal open={sodEodState.open}>
        {
          <sodEodState.Component
            fullView={parsedURL.report_type_platform === 'desktop'}
            animateOnMount={shiftReportingStatusData?.is_backtrack_allowed}
            close={(type: ReportType, param?: any) => {
              const parsed = parseURL(locationSearch);
              delete parsed.report_type_open;
              navigate(
                toURL(pathname, {
                  ...parsed,
                  ...param,
                }),
                { replace: true },
              );
              // Check if after being closed there's type provided
              // which means backtracking is allowed
              if (type === 'SOD' || type === 'EOD') {
                /**
                 * NOTE: Intended to differ the call here in order to properly
                 * close the conflict modal first before opening the next modal
                 */
                setTimeout(() => {
                  type === 'SOD'
                    ? onStartEndClick('SOD')
                    : onStartEndClick('EOD');
                }, 0);
              } else {
                shiftReportingStatus.refetch();
              }
            }}
          />
        }
      </Modal>
    );
  };

  /**
   * Listen when sod/eod form is open,
   * and update the url query state
   */
  useEffect(() => {
    const searchParsed = parseURL(locationSearch);

    /**
     * Check if it's not open and no report type is open, then there's
     * nothing to do here
     */
    if (!sodEodState.open && !searchParsed.report_type_open) {
      return;
    }

    /**
     * Check if the modal is already open
     */
    if (sodEodState.open) {
      // Check if there's no more type in the url
      if (!searchParsed.report_type_open) {
        setSodEodState({
          open: false,
          Component: DummyComponent,
        });
      }
      // Intended to return immediately if modal is already open
      return;
    }

    /**
     * Open only the SOD modal if the next shift status report type is also SOD
     */
    if (!!shiftReportingStatusData && searchParsed.report_type_open === 'SOD') {
      if (shiftReportingStatusData.next_report_type === 'SOD') {
        return setSodEodState({
          open: true,
          type: 'SOD',
          Component: SODForm,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }

    /**
     * Open only the EOD modal if the next shift status report type is also EOD
     * or if backtracking is allowed
     */
    if (!!shiftReportingStatusData && searchParsed.report_type_open === 'EOD') {
      if (
        shiftReportingStatusData.next_report_type === 'EOD' ||
        shiftReportingStatusData.is_backtrack_allowed
      ) {
        return setSodEodState({
          open: true,
          type: 'EOD',
          Component: EODForm,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }

    /**
     * Open only the SOD/EOD modal confirmation
     * if the backtracking is allowed and next shift status report type is SOD
     */
    if (
      !!shiftReportingStatusData &&
      searchParsed.report_type_open === 'SOD_EOD'
    ) {
      if (
        shiftReportingStatusData.is_backtrack_allowed &&
        shiftReportingStatusData.next_report_type === 'SOD'
      ) {
        return setSodEodState({
          open: true,
          type: 'SOD_EOD',
          Component: SodEodSelectionView,
        });
      } else {
        return setSodEodState({
          open: true,
          type: undefined,
          Component: SodEodErrorView,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, locationSearch, sodEodState, shiftReportingStatusData]);

  if (parsedURL.report_type_platform === 'desktop') {
    return <>{renderPlanRealityModal()}</>;
  }

  return null;
}

export default AppNavigationBar;
